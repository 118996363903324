<template>
  <div>
    <div v-if="stillLoading">
      <vue-simple-spinner size="large" voucher="Loading..."></vue-simple-spinner>
    </div>
    <div v-else>
      <b-progress v-show="showProgress" :value="counterProgress" :max="100" show-progress animated></b-progress>
      <b-col sm="12" tag="div">
        <b>
          <div slot="header">
            <strong style="color: black">
              {{ $route.meta.label }}
              <small> - Form </small>
            </strong>
          </div>
          <b-row tag="div">
            <b-col sm="12" tag="div">
              <vue-dropzone
                v-show="showProfileLogo"
                id="dropzone-logo"
                ref="dropzoneLogo"
                class="m-dropzone dropzone"
                :options="dropzoneLogoOptions"
                @vdropzone-success="dropzoneLogoSuccess"
                @vdropzone-removed-file="dropzoneRemovedSuccess"
              />
              <b-form-group breakpoint="sm" v-for="item in columns" v-bind:key="item.field">
                <label for="name" style="color: black">
                  {{ item.label }}
                </label>
                <img :src="options[item.field]" v-if="item.field == 'url'" />

                <b-form-input
                  v-else-if="item.field == 'name'"
                  :placeholder="'Enter ' + item.label + ' ...'"
                  type="text"
                  readonly="true"
                  v-model="options[item.field]"
                  :disabled="item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'"
                />
                <select v-else-if="item.field == 'kelas_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                  <option value="" selected="selected">ID Kelas</option>
                  <option v-for="(item_kelas, index_kelas) in list_kelas" v-bind:key="item_kelas.id" :value="item_kelas.id">{{ item_kelas.nama }}</option>
                </select>
                <select v-else-if="item.field == 'ecommerce'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                  <option v-for="(item_ecommerce, index_ecommerce) in list_ecommerce" v-bind:key="item_ecommerce" :value="item_ecommerce">{{ item_ecommerce }}</option>
                </select>
                <div v-else-if="item.field == 'google_doc_id'">
                  <b-form-input :placeholder="'Enter ' + item.label + ' ...'" type="text" v-model="options[item.field]" /><a
                    >contoh: https://docs.google.com/spreadsheets/d/<b>1wGttBNVCP-Rqn-jYk7bUK5G2gU1rnkxkRtQLCdUQgls</b>/edit#gid=1618110438</a
                  >
                  <br /><a>ketik: 1wGttBNVCP-Rqn-jYk7bUK5G2gU1rnkxkRtQLCdUQgls</a> <br /><a>share ke candra@g2academy.co dan candra.sulistiyono@gmail.com</a>
                </div>
                <b-form-input
                  v-else
                  :placeholder="'Enter ' + item.label + ' ...'"
                  type="text"
                  v-model="options[item.field]"
                  :disabled="item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button variant="primary" @click="submitData" v-if="($store.getters['customer/customer'].role_id = 1)">
            <i class="fa fa-dot-circle-o" />
            Submit
          </b-button>
        </b>
      </b-col>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'VoucherGenerate',
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      options: {},
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      columns: [
        { field: 'records', label: 'Records Generate' },
        { field: 'prefix', label: 'Prefix' },
        { field: 'ecommerce', label: 'Ecommerce' },
        { field: 'kelas_id', label: 'Kelas' },
        { field: 'google_doc_id', label: 'Google Doc Id' },
        { field: 'sheet_name', label: 'Sheet Name' }
      ],
      masters: {},
      customer: {},
      levelId: 0,
      locked: false,
      list_kelas: [],
      list_ecommerce: ['Sekolahmu', 'Tokopedia', 'Pijar', 'MBA', 'Bukalapak', 'Pintaria'],
      stillLoading: true,
      dropzoneLogoOptions: {
        url: window.axios.defaults.baseURL + 'api/attachment',
        maxFilesize: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        headers: {
          Authorization: 'jwt ' + this.$store.getters['customer/customer'].token
        }
      },
      showProfileLogo: false,
      profileLogo: ''
    }
  },
  methods: {
    dropzoneLogoSuccess: function (file, response) {
      //this.options.logo = response.data.path
      this.profileLogo = response.data.path
      //this.$store.getters['customer/customer'].logo
    },
    initData() {
      this.getListKelas()
      if (this.$route.meta.name == 'customer' && this.$route.meta.mode == 'Edit') {
        if (this.$route.params.id != this.$store.getters['customer/customer'].id) {
          this.locked = true
        }
      }
      if (this.locked) {
        return null
      }

      this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      this.showProgress = false
      this.stillLoading = false
    },
    submitData() {
      this.$store
        .dispatch('voucher/GENERATE_VOUCHER', {
          masterType: this.$route.meta.name,
          masterId: this.idForm,
          payload: this.options
        })
        .then((resp) => {
          if (resp.data.status === '200 OK') {
            if (this.customer.role_id > 2) {
              this.initData()
            } else {
              this.$router.push('/voucher')
            }
          }
          // this.$swal(resp.data.status);
        })
    },
    dropzoneRemovedSuccess: function (file, response) {
      this.profileLogo = ''
    },
    getListKelas() {
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: ''
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          customerId: this.clientId,
          masterType: this.$route.meta.name,
          params: paramsTemp
        })
        .then(() => {
          this.list_kelas = this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
        })
        .catch(function () {})
    }
  },
  computed: {
    data: function () {
      return this.$store.getters['voucher/list_voucher'] ? this.$store.getters['voucher/list_voucher'] : []
    },
    clientId() {
      return localStorage.getItem('client')
    }
  },
  created: function () {
    //this.initData();
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
