var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "voucher": "Loading..."
    }
  })], 1) : _c('div', [_c('b-progress', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showProgress,
      expression: "showProgress"
    }],
    attrs: {
      "value": _vm.counterProgress,
      "max": 100,
      "show-progress": "",
      "animated": ""
    }
  }), _c('b-col', {
    attrs: {
      "sm": "12",
      "tag": "div"
    }
  }, [_c('b', [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('strong', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.meta.label) + " "), _c('small', [_vm._v(" - Form ")])])]), _c('b-row', {
    attrs: {
      "tag": "div"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "tag": "div"
    }
  }, [_c('vue-dropzone', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showProfileLogo,
      expression: "showProfileLogo"
    }],
    ref: "dropzoneLogo",
    staticClass: "m-dropzone dropzone",
    attrs: {
      "id": "dropzone-logo",
      "options": _vm.dropzoneLogoOptions
    },
    on: {
      "vdropzone-success": _vm.dropzoneLogoSuccess,
      "vdropzone-removed-file": _vm.dropzoneRemovedSuccess
    }
  }), _vm._l(_vm.columns, function (item) {
    return _c('b-form-group', {
      key: item.field,
      attrs: {
        "breakpoint": "sm"
      }
    }, [_c('label', {
      staticStyle: {
        "color": "black"
      },
      attrs: {
        "for": "name"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), item.field == 'url' ? _c('img', {
      attrs: {
        "src": _vm.options[item.field]
      }
    }) : item.field == 'name' ? _c('b-form-input', {
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...',
        "type": "text",
        "readonly": "true",
        "disabled": item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    }) : item.field == 'kelas_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "selected": "selected"
      }
    }, [_vm._v("ID Kelas")]), _vm._l(_vm.list_kelas, function (item_kelas, index_kelas) {
      return _c('option', {
        key: item_kelas.id,
        domProps: {
          "value": item_kelas.id
        }
      }, [_vm._v(_vm._s(item_kelas.nama))]);
    })], 2) : item.field == 'ecommerce' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, _vm._l(_vm.list_ecommerce, function (item_ecommerce, index_ecommerce) {
      return _c('option', {
        key: item_ecommerce,
        domProps: {
          "value": item_ecommerce
        }
      }, [_vm._v(_vm._s(item_ecommerce))]);
    }), 0) : item.field == 'google_doc_id' ? _c('div', [_c('b-form-input', {
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...',
        "type": "text"
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    }), _c('a', [_vm._v("contoh: https://docs.google.com/spreadsheets/d/"), _c('b', [_vm._v("1wGttBNVCP-Rqn-jYk7bUK5G2gU1rnkxkRtQLCdUQgls")]), _vm._v("/edit#gid=1618110438")]), _c('br'), _c('a', [_vm._v("ketik: 1wGttBNVCP-Rqn-jYk7bUK5G2gU1rnkxkRtQLCdUQgls")]), _vm._v(" "), _c('br'), _c('a', [_vm._v("share ke candra@g2academy.co dan candra.sulistiyono@gmail.com")])], 1) : _c('b-form-input', {
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...',
        "type": "text",
        "disabled": item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1);
  })], 2)], 1), (_vm.$store.getters['customer/customer'].role_id = 1) ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.submitData
    }
  }, [_c('i', {
    staticClass: "fa fa-dot-circle-o"
  }), _vm._v(" Submit ")]) : _vm._e()], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }